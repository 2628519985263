// FOOTER

.wrapper_footer {
    padding-left: var(--body-side);
    padding-right: var(--body-side);
    padding-top: var(--gallery-gap-mobile);
    position: relative;
    color: var(--title-color);
    text-shadow: 1px 1px 5px var(--network-color);

    &::before {
        content: '@Guillaume Delorme reserved';
        position: absolute;
        bottom: 0;
        left: 5px;
        font-size: .6em;
        text-shadow: none;
    }

    &::after {
        content: '';
        z-index: -2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: .5;
        background-image: url(../assets/img/backgrounds/bg-footer.webp);
        background-size: cover;
        background-position: center;
    }

    @media #{$mobileScreen} {
        padding-top: calc(var(--gallery-gap) / 2);
    }
}

.description_footer {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: calc(var(--footer-gap) / 2);
    border-radius: var(--corner-item);

    .link_contact {
        display: flex;
        flex-direction: column;
        place-content: center;
        gap: 1rem;
    }

    .text_contact {
        font-weight: var(--font-semibold);

        @media #{$mobileScreen} {
            padding: 0 2rem;
        }

        .smile {
            width: 1.3em;
            height: auto;
            display: inline;
            position: relative;
            top: 5px;
        }
    }
}

.link {
    cursor: pointer;
    position: relative;
    display: inline-flex;
    place-items: center;
    margin: auto;
    background: var(--network-color);
    color: var(--title-color);
    font-family: var(--link-family);
    border: 2px solid transparent;
    border-radius: var(--corner-item);
    text-shadow: none;
    padding: .5em 1em;
    letter-spacing: 1px;
    transition: background .15s;

    @media #{$downScreen} {
        font-size: calc(var(--index) * 1.5);
    }

    &:hover {
        background: var(--network-color-hover);
    }

    &:hover>.copy_icon {
        background-color: var(--network-color);
        border: 1px solid var(--network-color);
    }

    &:hover>.copy_bubble {
        visibility: visible;
        opacity: 1;
    }
}

.copy_icon {
    cursor: pointer;
    content: url(../assets/img/icons/copy.svg);
    position: relative;
    margin-left: 1em;
    height: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    place-self: center;
    border: 1px solid var(--network-color-hover);
    padding: 6px;
    border-radius: var(--corner-item);
    transition: border .15s, background .15s;

    &:hover+.copy_bubble {
        visibility: visible;
        opacity: 1;
    }
}

.copy_bubble {
    position: relative;
    background: var(--second-background);
    color: var(--font-color-light);
    position: absolute;
    visibility: hidden;
    opacity: 0;
    right: 3px;
    top: -1.4em;
    padding: 0 .7em;
    font-size: .6em;
    border-radius: 5px;
    transition: opacity var(--time);

    &::after {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 50%;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid var(--second-background);
        transform: rotate(180deg) translate(50%, 0%);
    }
}

.contact_footer {
    margin-top: var(--footer-gap);
    display: flex;
    flex-direction: column;
    place-items: center;
    gap: var(--footer-gap);
}

.footer_block {
    flex: 1;
    display: grid;
}

.networks {
    grid-template-columns: repeat(3, auto);
    grid-gap: calc(var(--space-block-footer) / 2);
    grid-auto-rows: auto;
    width: 100%;

    @media #{$mobileScreen} {
        width: auto;
    }

    .network_item {
        height: auto;
        aspect-ratio: 1/1;
        background: var(--color-background);
        border-radius: var(--corner-item);

        @media #{$mobileScreen} {
            width: 125px;
        }

        &>* {
            display: flex;
            place-content: center;
            place-items: center;
        }
    }

    .envelope,
    .instagram,
    .phone {
        position: relative;
        display: flex;
        background: var(--second-background);
        grid-row: 1;

        &:hover::before {
            transform: scale(1.1);
            box-shadow: 0 0 15px 4px var(--important-color);
        }

        &:hover svg {
            fill: var(--important-color);
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: var(--corner-item);
            background: var(--important-color);
            transform: scale(.95);
            z-index: -1;
            transition: transform var(--time), box-shadow calc(var(--time) + 50ms);
        }

        & svg {
            transition: fill var(--time), box-shadow var(--time);
            fill: var(--font-color-light);
        }
    }
}

.form_content {
    width: 100%;
    text-align: center;

    .form_title {
        margin-bottom: 1vh;
    }
}

.form {
    width: 100%;
    grid-template-columns: none;
    grid-gap: var(--space-block-footer);
    grid-auto-rows: auto;

    @media #{$tabletScreen} {
        grid-template-columns: repeat(2, 1fr);
    }

    .input_block {
        width: 100%;
        padding: 1rem;
        border-radius: var(--corner-item);

        &::placeholder,
        &[type='submit'] {
            color: var(--font-color);
            font-family: var(--font-family);
        }

        &:not(.send) {
            font-family: var(--font-family);
            font-weight: var(--font-semibold);
            border: none;
            font-size: .7em;
            background: var(--input-color);
            border: 2px solid var(--important-color);
            transition: border var(--time), background var(--time);

            @media #{$tabletScreen} {
                padding-left: 2rem;
                width: 80%;
            }

            &:hover {
                background: var(--input-color-opacity);
                border-color: var(--important-color);
                border-style: dashed;
            }

            &:focus {
                background: var(--white);
                border-color: var(--important-color);
                border-style: dashed;

                &::placeholder {
                    color: transparent;
                }
            }

            &::placeholder {
                font-weight: var(--font-regular);
            }
        }
    }

    .name {
        grid-column: 1;
        grid-row: 1;
        margin-left: auto;

        @media #{$tabletScreen} {
            border-bottom-right-radius: 0;
        }
    }

    .email {
        grid-column: 1;
        grid-row: 2;

        @media #{$tabletScreen} {
            grid-column: 2;
            grid-row: 1;
            border-bottom-left-radius: 0;
        }
    }

    .number {
        grid-column: 1;
        grid-row: 3;

        @media #{$tabletScreen} {
            grid-column: 2;
            grid-row: 2;
            height: fit-content;
            border-top-left-radius: 0;
        }
    }

    .message {
        grid-column: 1;
        grid-row: 4;
        margin-left: auto;
        resize: none;

        @media #{$tabletScreen} {
            grid-column: 1;
            grid-row: 2 / 5;
            border-top-right-radius: 0;
        }
    }

    .send {
        cursor: pointer;
        grid-column: 1;
        grid-row: 5;
        font-size: 1.4em;
        font-weight: var(--font-semibold);
        text-transform: uppercase;
        background: var(--important-color);
        border: 2px solid transparent;
        transition: background var(--time), border var(--time);

        &:hover {
            background: var(--important-color-opacity);
            border: 2px solid var(--important-color);
        }

        @media #{$tabletScreen} {
            grid-column: 2;
            grid-row: 3/5;
            width: 50%;
        }
    }
}

#success,
#copy,
#error {
    position: fixed;
    top: 0;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -100%);
    border-radius: calc(var(--corner-item) / 2);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    text-align: center;
    font-weight: var(--font-semibold);
    font-size: .8em;
    padding: .6em 1em;
    visibility: hidden;

    @media #{$tabletScreen} {
        width: auto;
    }
}

#success {
    background: var(--success-color);
    text-transform: uppercase;
}

#copy {
    background: var(--second-background);
    color: var(--input-color);
}

#error {
    background: var(--error-color);
}

.down {
    animation: down 450ms ease both;
}

.error {
    border: 2px solid var(--error-color) !important;

    &::placeholder {
        color: var(--error-color) !important;
        font-weight: var(--font-semibold) !important;
    }
}

@keyframes down {
    to {
        visibility: visible;
        transform: translate(-50%, 0%);
    }
}

.thanks {
    font-family: var(--title-family);
    font-size: 5em;
}

.mentions_footer {
    background: var(--second-background);
    color: var(--font-color-light);
    text-align: center;
    font-size: .7em;

    @media #{$mobileScreen} {
        font-size: .8em;
    }
}