@import './base';
@import './layout/_header';
@import './layout/_main';
@import './layout/_footer';

body {
    font-family: var(--font-family);
    line-height: var(--font-line);
    font-weight: var(--font-regular);
    font-size: var(--font-size);

    color: var(--font-color);
    background-color: var(--main-background);

    font-synthesis: none;
    text-rendering: auto;
    overflow-x: hidden;
}

h1 {
    font-family: var(--title-family);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: var(--font-semibold);
    color: var(--title-color);
}

a,
img:not(.img_header) {
    width: 100%;
    height: 100%;
    border-radius: inherit;
    display: flex;
}

.content,
.img_header,
.wrapper_header,
.gallery>* {
    will-change: transform;
}

.wrapper {
    margin: auto;
    display: flex;
    flex-direction: column;
}

.partition {
    background: var(--black);
    border: 10px solid var(--black);
    border-left: none;
    border-right: none;
    overflow-x: scroll;
    scrollbar-width: 10px;

    @media #{$tabletScreen} {
        overflow-x: auto;
    }

    &::after {
        content: '';
        background-image: url(../assets/img/profil-portable.webp);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 49rem;
        height: auto;
        aspect-ratio: 3074/1600;
        display: flex;

        @media #{$tabletScreen} {
            background-image: url(../assets/img/profil-desktop.webp);
            width: 100%;
            height: auto;
            aspect-ratio: 3047/410;
        }
    }
}

// PICTURE ZOOM

.zoom {
    cursor: zoom-in;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-color: var(--black-light-opaque);
    padding: 10%;
    display: none;
}

.cross {
    cursor: pointer;
    position: absolute;
    top: .5rem;
    right: .5rem;
    width: 30px;
    height: auto;
    aspect-ratio: 1/1;

    &:hover {

        &::before,
        &::after {
            background: var(--white-light);
        }
    }

    &::before,
    &::after {
        content: '';
        display: flex;
        position: relative;
        top: 50%;
        background-color: var(--white-light-opaque);
        height: 5px;
        border-radius: 2px;
        transition: background var(--time);
    }

    &::before {
        transform: translate(0%, -25%) rotate(45deg);
    }

    &::after {
        transform: translate(0%, -125%) rotate(-45deg);
    }
}

// BUTTON RETURN

.button_return {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: .5rem;
    right: .5rem;
    width: 40px;
    height: auto;
    aspect-ratio: 1/1;
    background-color: rgba(0, 0, 0, .2);
    margin: 0;
    border-radius: 35%;
    transition: all calc(var(--time) - 100ms) linear;

    &:hover {
        background-color: rgba(0, 0, 0, .35) !important;
    }

    &:active {
        background-color: rgba(0, 0, 0, .35) !important;
    }

    @media #{$tabletScreen} {
        width: 50px;
    }
}

.show {
    opacity: 1 !important;
}

.hide {
    opacity: 0 !important;
    pointer-events: none;
}

// BOTTOM ARROW

.bottom_arrow,
.bottom_arrow::before {
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0%);
    height: calc(var(--index) * 2);
    min-height: 32px;
    max-height: 70px;
    width: auto;
    aspect-ratio: 1/1;
}

.bottom_arrow {
    cursor: pointer;
    bottom: 2vh;
    background: transparent;
    transition: opacity calc(var(--time) - 100ms) linear;

    &:hover::before {
        animation: none;
    }

    &::before {
        content: '';
        display: flex;
        background-image: url(../assets/img/icons/arrow-down.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        opacity: 1;
        animation: opacity 1500ms linear infinite;
    }
}

@keyframes opacity {
    0% {
        opacity: 1;
    }

    50% {
        opacity: .2;
    }

    100% {
        opacity: 1;
    }
}