@import "https://fonts.googleapis.com/css2?family=Krub:wght@400;600;700&family=Source+Code+Pro&display=swap";
@font-face {
  font-family: heaters;
  src: url("Heaters.6f16e30e.otf");
  font-display: swap;
}

:root {
  --font-family: "Krub", sans-serif;
  --title-family: "heaters", sans-serif;
  --link-family: "Source Code Pro", monospace;
  --font-regular: 400;
  --font-semibold: 600;
  --font-bold: 700;
  --font-line: 1.8;
  --font-size: 18px;
  --title-size: 2.35rem;
  --font-color: var(--black-light);
  --font-color-light: var(--gray-light);
  --title-color: var(--black);
  --success-color: var(--green);
  --error-color: var(--red);
  --important-color: var(--orange);
  --important-color-opacity: var(--orange-opaque-up);
  --border-paragraph: var(--orange-opaque);
  --shadow-color: var(--black-light);
  --input-color: var(--white-light);
  --input-color-opacity: var(--white-light-opaque-up);
  --line-color-opacity: var(--black-light-opaque-up);
  --network-color: var(--orange);
  --network-color-hover: var(--orange-gray);
  --main-background: var(--beige);
  --second-background: var(--gray);
  --black: #000;
  --black-light: #333;
  --black-light-opaque: #333c;
  --black-light-opaque-up: #3333;
  --gray: #303030;
  --gray-light: gray;
  --white: #fff;
  --white-light: #f3f3f3;
  --white-light-opaque: #f3f3f3a6;
  --white-light-opaque-up: #f3f3f333;
  --beige: #f7d1b8;
  --purple: #962938;
  --red: #f34e4e;
  --orange-gray: #db652ae6;
  --orange: #ff6a1fe6;
  --orange-opaque: #ff6a1f80;
  --orange-opaque-up: #ff6a1f33;
  --green: #d5cd15;
  --yellow: #daa520;
  --desktop: 1280px;
  --tablet: 790px;
  --mobile: 375px;
  --index: calc(1vw + 1vh);
  --multi-width-item: 15;
  --gallery-gap-mobile: 4rem;
  --gallery-gap: min(16rem, calc(var(--index) * 10));
  --footer-gap: calc(var(--index) * 5);
  --space-block-footer: 10px;
  --corner-item: 7px;
  --side: 2vw;
  --side-double: calc(var(--side) * 2);
  --body-side: max(var(--side), calc(50% - var(--mobile) / 2));
  --time: .25s;
}

@media only screen and (width >= 560px) {
  :root {
    --body-side: min(var(--side), calc(50% - var(--mobile) / 2));
  }
}

@media only screen and (width >= 1280px) {
  :root {
    --body-side: max(var(--side), calc(50% - var(--desktop) / 2));
  }
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style-type: none;
  outline: #0000 !important;
}

.header {
  padding-left: var(--body-side);
  padding-right: var(--body-side);
  text-align: center;
  min-height: 100vh;
  position: relative;
}

.header .img_header {
  background: var(--main-background);
  width: calc(var(--index) * 30);
  z-index: -1;
  max-width: 100%;
  margin-top: 2rem;
}

@media only screen and (width >= 560px) {
  .header .img_header {
    margin-top: 0;
    position: absolute;
    top: 8vh;
    left: auto;
    right: 0;
    transform: translate(0%);
  }
}

@media only screen and (width >= 1440px) {
  .header .img_header {
    right: 5%;
  }
}

.wrapper_header {
  position: relative;
}

@media only screen and (width >= 560px) {
  .wrapper_header {
    min-height: 100vh;
  }
}

.wrapper_header .description_header {
  text-align: center;
  width: 100%;
}

@media only screen and (width >= 560px) {
  .wrapper_header .description_header {
    text-align: left;
    width: 60%;
    margin-left: 7vw;
    position: absolute;
    bottom: 20vh;
  }
}

@media only screen and (width >= 1440px) {
  .wrapper_header .description_header {
    margin-left: 0;
  }
}

.wrapper_header .title_header {
  font-size: calc(var(--index) * 6);
  line-height: 1.1;
}

.wrapper_header .text_header {
  color: var(--title-color);
  font-weight: var(--font-semibold);
  line-height: 1.5;
}

.wrapper_main {
  padding-left: var(--body-side);
  padding-right: var(--body-side);
  padding-bottom: var(--gallery-gap-mobile);
  position: relative;
}

@media only screen and (width >= 560px) {
  .wrapper_main {
    padding-bottom: calc(var(--side) * 2);
  }
}

@media only screen and (width >= 1280px) {
  .wrapper_main {
    padding-bottom: 0;
  }
}

.gallery {
  flex-direction: column-reverse;
  display: flex;
}

@media only screen and (width >= 560px) {
  .gallery {
    flex-direction: row;
  }
}

.gallery > * {
  flex-direction: column;
  flex: 1;
  place-items: center;
  display: flex;
}

.gallery_profil {
  box-shadow: 0px 1px 5px var(--shadow-color);
}

@media only screen and (width >= 560px) {
  .gallery_profil.mobileProfil {
    display: none;
  }
}

.gallery_profil.desktopProfil {
  display: none;
}

@media only screen and (width >= 560px) {
  .gallery_profil.desktopProfil {
    display: flex;
  }
}

.gallery_bonus {
  display: none;
}

@media only screen and (width >= 560px) {
  .gallery_left {
    margin-right: var(--side);
    margin-top: min(var(--gallery-gap) * 2, 24rem);
  }
}

@media only screen and (width >= 1280px) {
  .gallery_left {
    margin-top: min(var(--gallery-gap) * 2, 18rem);
  }
}

@media only screen and (width >= 560px) {
  .gallery_right {
    margin-left: var(--side);
  }
}

.gallery_right .gallery_item {
  margin-bottom: 0;
}

.gallery_item {
  margin-top: var(--gallery-gap-mobile);
  border-radius: var(--corner-item);
  width: 100%;
}

@media only screen and (width >= 560px) {
  .gallery_item {
    margin-top: var(--gallery-gap);
    max-width: calc(var(--index) * var(--multi-width-item));
  }
}

@media only screen and (width >= 790px) {
  .gallery_item {
    margin-top: var(--gallery-gap);
  }
}

.gallery_drawing {
  cursor: zoom-in;
  box-shadow: 0px 1px 5px var(--shadow-color);
  position: relative;
}

.gallery_drawing:before {
  content: "@artist reserved";
  font-size: .6em;
  position: absolute;
  bottom: 0;
  left: 5px;
}

.gallery_drawing:after {
  content: "";
  cursor: pointer;
  border: 2px solid var(--line-color-opacity);
  border-radius: calc(var(--corner-item) / 2);
  transition: all var(--time);
  background-color: #0000;
  background-image: url("zoom.1a4eb516.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 22px 22px;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  bottom: .5rem;
  right: .5rem;
}

.gallery_drawing:hover:after {
  border: 2px solid var(--important-color);
  background-color: var(--important-color-opacity);
}

.rights_light {
  color: var(--white-light);
}

.no_right {
  color: #0000;
}

.gallery_paragraph {
  border: 3px solid var(--border-paragraph);
  padding: 1rem;
}

@media only screen and (width >= 560px) {
  .gallery_paragraph {
    max-width: calc(var(--index) * (var(--multi-width-item)  + 10));
  }
}

.description_block {
  text-align: center;
  position: relative;
}

@media only screen and (width >= 560px) {
  .description_block {
    text-align: left;
  }
}

.description_block .title_description_block {
  font-size: var(--title-size);
  margin-bottom: 4vh;
  line-height: 1.1;
}

.description_block .text_description_block, .description_block .city, .description_block .list {
  font-size: .85em;
}

@media only screen and (width >= 790px) {
  .description_block .text_description_block, .description_block .city, .description_block .list {
    font-size: 1em;
  }
}

.description_block .city {
  text-decoration: underline;
}

.description_block .list li {
  list-style-image: url("diamond.156669ce.svg");
  list-style-position: inside;
}

.description_block .br {
  margin-bottom: 2vh;
}

.description_block br {
  height: 2vh;
  display: flex;
}

.carousel {
  --side-picture: 50px;
  background: var(--second-background);
  box-shadow: 0px 2px 5px var(--shadow-color);
  padding-left: var(--side-picture);
  padding-right: var(--side-picture);
  position: relative;
  overflow: hidden;
}

@media only screen and (width >= 560px) {
  .carousel {
    max-width: calc(var(--index) * (var(--multi-width-item)  - 2)  + var(--side-picture) * 2);
  }
}

.carousel #switchBlock {
  margin-top: calc(var(--side-picture) / 2);
  margin-bottom: calc(var(--side-picture) * 1.5);
  place-items: center;
  display: flex;
}

.carousel #switchBlock .picture {
  cursor: grab;
  border-radius: var(--corner-item);
  box-shadow: none;
  transition: transform .65s, border var(--time);
  transition-delay: 50ms;
  position: relative;
  height: auto !important;
}

.carousel #switchBlock .picture:before {
  content: "@artist reserved";
  font-size: .6em;
  position: absolute;
  bottom: 0;
  left: 5px;
}

.carousel #switchBlock .picture .img_carousel {
  border-radius: 0;
}

.carousel .zoom_button {
  content: "";
  cursor: pointer;
  border: 2px solid var(--line-color-opacity);
  border-radius: calc(var(--corner-item) / 2);
  transition: all var(--time);
  background-color: #0000;
  background-image: url("zoom.1a4eb516.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 22px 22px;
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  bottom: .5rem;
  right: .5rem;
}

.carousel .zoom_button:hover {
  border: 2px solid var(--important-color);
  background-color: var(--important-color-opacity);
}

.carousel .points_container {
  gap: 5px;
  display: flex;
  position: absolute;
  bottom: 2%;
  left: 50%;
  transform: translate(-50%);
}

@media only screen and (width >= 560px) {
  .carousel .points_container {
    bottom: 4%;
  }
}

.carousel .points {
  background: var(--white-light-opaque-up);
  aspect-ratio: 1;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  transition: background 2s, transform .75s;
}

.carousel .buttonLeft, .carousel .buttonRight {
  cursor: pointer;
  width: calc(var(--index) * 2);
  aspect-ratio: 1;
  visibility: hidden;
  opacity: 0;
  transition: background calc(var(--time) / 2), opacity var(--time), visibility var(--time);
  background-repeat: no-repeat;
  border-radius: 50%;
  min-width: 25px;
  max-width: 60px;
  height: auto;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  bottom: 1%;
}

@media only screen and (width >= 560px) {
  .carousel .buttonLeft, .carousel .buttonRight {
    bottom: 2%;
  }
}

.carousel .buttonLeft:hover, .carousel .buttonRight:hover {
  background-color: var(--orange);
  animation: none;
}

.carousel .buttonLeft {
  background-image: url("arrow-left.9a04ca63.svg");
  left: 3%;
}

.carousel .buttonRight {
  background-image: url("arrow-right.c27eee30.svg");
  right: 3%;
}

@keyframes scale {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(.95);
  }
}

.wrapper_footer {
  padding-left: var(--body-side);
  padding-right: var(--body-side);
  padding-top: var(--gallery-gap-mobile);
  color: var(--title-color);
  text-shadow: 1px 1px 5px var(--network-color);
  position: relative;
}

.wrapper_footer:before {
  content: "@Guillaume Delorme reserved";
  text-shadow: none;
  font-size: .6em;
  position: absolute;
  bottom: 0;
  left: 5px;
}

.wrapper_footer:after {
  content: "";
  z-index: -2;
  opacity: .5;
  background-image: url("bg-footer.cfee585c.webp");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (width >= 560px) {
  .wrapper_footer {
    padding-top: calc(var(--gallery-gap) / 2);
  }
}

.description_footer {
  text-align: center;
  gap: calc(var(--footer-gap) / 2);
  border-radius: var(--corner-item);
  flex-direction: column;
  display: flex;
}

.description_footer .link_contact {
  flex-direction: column;
  place-content: center;
  gap: 1rem;
  display: flex;
}

.description_footer .text_contact {
  font-weight: var(--font-semibold);
}

@media only screen and (width >= 560px) {
  .description_footer .text_contact {
    padding: 0 2rem;
  }
}

.description_footer .text_contact .smile {
  width: 1.3em;
  height: auto;
  display: inline;
  position: relative;
  top: 5px;
}

.link {
  cursor: pointer;
  background: var(--network-color);
  color: var(--title-color);
  font-family: var(--link-family);
  border-radius: var(--corner-item);
  text-shadow: none;
  letter-spacing: 1px;
  border: 2px solid #0000;
  place-items: center;
  margin: auto;
  padding: .5em 1em;
  transition: background .15s;
  display: inline-flex;
  position: relative;
}

@media only screen and (width <= 370px) {
  .link {
    font-size: calc(var(--index) * 1.5);
  }
}

.link:hover {
  background: var(--network-color-hover);
}

.link:hover > .copy_icon {
  background-color: var(--network-color);
  border: 1px solid var(--network-color);
}

.link:hover > .copy_bubble {
  visibility: visible;
  opacity: 1;
}

.copy_icon {
  cursor: pointer;
  content: url("copy.dc3968b5.svg");
  border: 1px solid var(--network-color-hover);
  border-radius: var(--corner-item);
  place-self: center;
  height: -moz-fit-content;
  margin-left: 1em;
  padding: 6px;
  transition: border .15s, background .15s;
  position: relative;
}

.copy_icon:hover + .copy_bubble {
  visibility: visible;
  opacity: 1;
}

.copy_bubble {
  background: var(--second-background);
  color: var(--font-color-light);
  visibility: hidden;
  opacity: 0;
  transition: opacity var(--time);
  border-radius: 5px;
  padding: 0 .7em;
  font-size: .6em;
  position: absolute;
  top: -1.4em;
  right: 3px;
}

.copy_bubble:after {
  content: "";
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  border-bottom: 5px solid var(--second-background);
  width: 0;
  height: 0;
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: rotate(180deg)translate(50%);
}

.contact_footer {
  margin-top: var(--footer-gap);
  place-items: center;
  gap: var(--footer-gap);
  flex-direction: column;
  display: flex;
}

.footer_block {
  flex: 1;
  display: grid;
}

.networks {
  grid-gap: calc(var(--space-block-footer) / 2);
  grid-template-columns: repeat(3, auto);
  grid-auto-rows: auto;
  width: 100%;
}

@media only screen and (width >= 560px) {
  .networks {
    width: auto;
  }
}

.networks .network_item {
  aspect-ratio: 1;
  background: var(--color-background);
  border-radius: var(--corner-item);
  height: auto;
}

@media only screen and (width >= 560px) {
  .networks .network_item {
    width: 125px;
  }
}

.networks .network_item > * {
  place-content: center;
  place-items: center;
  display: flex;
}

.networks .envelope, .networks .instagram, .networks .phone {
  background: var(--second-background);
  grid-row: 1;
  display: flex;
  position: relative;
}

.networks .envelope:hover:before, .networks .instagram:hover:before, .networks .phone:hover:before {
  box-shadow: 0 0 15px 4px var(--important-color);
  transform: scale(1.1);
}

.networks .envelope:hover svg, .networks .instagram:hover svg, .networks .phone:hover svg {
  fill: var(--important-color);
}

.networks .envelope:before, .networks .instagram:before, .networks .phone:before {
  content: "";
  border-radius: var(--corner-item);
  background: var(--important-color);
  z-index: -1;
  transition: transform var(--time), box-shadow calc(var(--time)  + 50ms);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(.95);
}

.networks .envelope svg, .networks .instagram svg, .networks .phone svg {
  transition: fill var(--time), box-shadow var(--time);
  fill: var(--font-color-light);
}

.form_content {
  text-align: center;
  width: 100%;
}

.form_content .form_title {
  margin-bottom: 1vh;
}

.form {
  grid-gap: var(--space-block-footer);
  grid-template-columns: none;
  grid-auto-rows: auto;
  width: 100%;
}

@media only screen and (width >= 790px) {
  .form {
    grid-template-columns: repeat(2, 1fr);
  }
}

.form .input_block {
  border-radius: var(--corner-item);
  width: 100%;
  padding: 1rem;
}

.form .input_block::placeholder, .form .input_block[type="submit"] {
  color: var(--font-color);
  font-family: var(--font-family);
}

.form .input_block:not(.send) {
  font-family: var(--font-family);
  font-weight: var(--font-semibold);
  background: var(--input-color);
  border: none;
  border: 2px solid var(--important-color);
  transition: border var(--time), background var(--time);
  font-size: .7em;
}

@media only screen and (width >= 790px) {
  .form .input_block:not(.send) {
    width: 80%;
    padding-left: 2rem;
  }
}

.form .input_block:not(.send):hover {
  background: var(--input-color-opacity);
  border-color: var(--important-color);
  border-style: dashed;
}

.form .input_block:not(.send):focus {
  background: var(--white);
  border-color: var(--important-color);
  border-style: dashed;
}

.form .input_block:not(.send):focus::placeholder {
  color: #0000;
}

.form .input_block:not(.send)::placeholder {
  font-weight: var(--font-regular);
}

.form .name {
  grid-area: 1 / 1;
  margin-left: auto;
}

@media only screen and (width >= 790px) {
  .form .name {
    border-bottom-right-radius: 0;
  }
}

.form .email {
  grid-area: 2 / 1;
}

@media only screen and (width >= 790px) {
  .form .email {
    border-bottom-left-radius: 0;
    grid-area: 1 / 2;
  }
}

.form .number {
  grid-area: 3 / 1;
}

@media only screen and (width >= 790px) {
  .form .number {
    border-top-left-radius: 0;
    grid-area: 2 / 2;
    height: -moz-fit-content;
    height: fit-content;
  }
}

.form .message {
  resize: none;
  grid-area: 4 / 1;
  margin-left: auto;
}

@media only screen and (width >= 790px) {
  .form .message {
    border-top-right-radius: 0;
    grid-area: 2 / 1 / 5;
  }
}

.form .send {
  cursor: pointer;
  font-size: 1.4em;
  font-weight: var(--font-semibold);
  text-transform: uppercase;
  background: var(--important-color);
  transition: background var(--time), border var(--time);
  border: 2px solid #0000;
  grid-area: 5 / 1;
}

.form .send:hover {
  background: var(--important-color-opacity);
  border: 2px solid var(--important-color);
}

@media only screen and (width >= 790px) {
  .form .send {
    grid-area: 3 / 2 / 5;
    width: 50%;
  }
}

#success, #copy, #error {
  border-radius: calc(var(--corner-item) / 2);
  text-align: center;
  font-weight: var(--font-semibold);
  visibility: hidden;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  padding: .6em 1em;
  font-size: .8em;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}

@media only screen and (width >= 790px) {
  #success, #copy, #error {
    width: auto;
  }
}

#success {
  background: var(--success-color);
  text-transform: uppercase;
}

#copy {
  background: var(--second-background);
  color: var(--input-color);
}

#error {
  background: var(--error-color);
}

.down {
  animation: .45s both down;
}

.error {
  border: 2px solid var(--error-color) !important;
}

.error::placeholder {
  color: var(--error-color) !important;
  font-weight: var(--font-semibold) !important;
}

@keyframes down {
  to {
    visibility: visible;
    transform: translate(-50%);
  }
}

.thanks {
  font-family: var(--title-family);
  font-size: 5em;
}

.mentions_footer {
  background: var(--second-background);
  color: var(--font-color-light);
  text-align: center;
  font-size: .7em;
}

@media only screen and (width >= 560px) {
  .mentions_footer {
    font-size: .8em;
  }
}

body {
  font-family: var(--font-family);
  line-height: var(--font-line);
  font-weight: var(--font-regular);
  font-size: var(--font-size);
  color: var(--font-color);
  background-color: var(--main-background);
  font-synthesis: none;
  text-rendering: auto;
  overflow-x: hidden;
}

h1 {
  font-family: var(--title-family);
}

h1, h2, h3, h4, h5, h6 {
  font-weight: var(--font-semibold);
  color: var(--title-color);
}

a, img:not(.img_header) {
  border-radius: inherit;
  width: 100%;
  height: 100%;
  display: flex;
}

.content, .img_header, .wrapper_header, .gallery > * {
  will-change: transform;
}

.wrapper {
  flex-direction: column;
  margin: auto;
  display: flex;
}

.partition {
  background: var(--black);
  border: 10px solid var(--black);
  scrollbar-width: 10px;
  border-left: none;
  border-right: none;
  overflow-x: scroll;
}

@media only screen and (width >= 790px) {
  .partition {
    overflow-x: auto;
  }
}

.partition:after {
  content: "";
  aspect-ratio: 3074 / 1600;
  background-image: url("profil-portable.0edfae31.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 49rem;
  height: auto;
  display: flex;
}

@media only screen and (width >= 790px) {
  .partition:after {
    aspect-ratio: 3047 / 410;
    background-image: url("profil-desktop.b5cdd440.webp");
    width: 100%;
    height: auto;
  }
}

.zoom {
  cursor: zoom-in;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
  background-color: var(--black-light-opaque);
  width: 100vw;
  height: 100vh;
  padding: 10%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.cross {
  cursor: pointer;
  aspect-ratio: 1;
  width: 30px;
  height: auto;
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.cross:hover:before, .cross:hover:after {
  background: var(--white-light);
}

.cross:before, .cross:after {
  content: "";
  background-color: var(--white-light-opaque);
  transition: background var(--time);
  border-radius: 2px;
  height: 5px;
  display: flex;
  position: relative;
  top: 50%;
}

.cross:before {
  transform: translate(0%, -25%)rotate(45deg);
}

.cross:after {
  transform: translate(0%, -125%)rotate(-45deg);
}

.button_return {
  cursor: pointer;
  aspect-ratio: 1;
  transition: all calc(var(--time)  - .1s) linear;
  background-color: #0003;
  border-radius: 35%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: auto;
  margin: 0;
  display: flex;
  position: fixed;
  bottom: .5rem;
  right: .5rem;
}

.button_return:hover, .button_return:active {
  background-color: #00000059 !important;
}

@media only screen and (width >= 790px) {
  .button_return {
    width: 50px;
  }
}

.show {
  opacity: 1 !important;
}

.hide {
  pointer-events: none;
  opacity: 0 !important;
}

.bottom_arrow, .bottom_arrow:before {
  height: calc(var(--index) * 2);
  aspect-ratio: 1;
  width: auto;
  min-height: 32px;
  max-height: 70px;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
}

.bottom_arrow {
  cursor: pointer;
  transition: opacity calc(var(--time)  - .1s) linear;
  background: none;
  bottom: 2vh;
}

.bottom_arrow:hover:before {
  animation: none;
}

.bottom_arrow:before {
  content: "";
  opacity: 1;
  background-image: url("arrow-down.d81b59ee.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  animation: 1.5s linear infinite opacity;
  display: flex;
}

@keyframes opacity {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .2;
  }

  100% {
    opacity: 1;
  }
}

/*# sourceMappingURL=index.55515b51.css.map */
