@import url('https://fonts.googleapis.com/css2?family=Krub:wght@400;600;700&family=Source+Code+Pro&display=swap');
@import './responsive.scss';

@font-face {
    font-family: 'heaters';
    src: url(../assets/fonts/Heaters.otf);
    font-display: swap;
}

:root {
    --font-family: 'Krub', sans-serif;
    --title-family: 'heaters', sans-serif;
    --link-family: 'Source Code Pro', monospace;
    --font-regular: 400;
    --font-semibold: 600;
    --font-bold: 700;
    --font-line: 1.8;
    --font-size: 18px;
    --title-size: 2.35rem;

    --font-color: var(--black-light);
    --font-color-light: var(--gray-light);
    --title-color: var(--black);
    --success-color: var(--green);
    --error-color: var(--red);
    --important-color: var(--orange);
    --important-color-opacity: var(--orange-opaque-up);
    --border-paragraph: var(--orange-opaque);
    --shadow-color: var(--black-light);
    --input-color: var(--white-light);
    --input-color-opacity: var(--white-light-opaque-up);
    --line-color-opacity: var(--black-light-opaque-up);
    --network-color: var(--orange);
    --network-color-hover: var(--orange-gray);
    --main-background: var(--beige);
    --second-background: var(--gray);

    --black: #000;
    --black-light: #333;
    --black-light-opaque: rgba(51, 51, 51, 0.8);
    --black-light-opaque-up: rgba(51, 51, 51, 0.2);
    --gray: #303030;
    --gray-light: #808080;
    --white: #fff;
    --white-light: #f3f3f3;
    --white-light-opaque: rgba(243, 243, 243, 0.65);
    --white-light-opaque-up: rgba(243, 243, 243, 0.2);
    --beige: #f7d1b8;
    --purple: #962938;
    --red: rgb(243, 78, 78);
    --orange-gray: rgba(219, 101, 42, 0.9);
    --orange: rgba(255, 106, 31, 0.9);
    --orange-opaque: rgba(255, 106, 31, 0.5);
    --orange-opaque-up: rgba(255, 106, 31, 0.2);
    --green: #d5cd15;
    --yellow: #daa520;

    --desktop: 1280px;
    --tablet: 790px;
    --mobile: 375px;
    --index: calc(1vw + 1vh);
    --multi-width-item: 15;
    --gallery-gap-mobile: 4rem;
    --gallery-gap: min(16rem, calc(var(--index) * 10));
    --footer-gap: calc(var(--index) * 5);
    --space-block-footer: 10px;
    --corner-item: 7px;
    --side: 2vw;
    --side-double: calc(var(--side) * 2);
    --body-side: max(var(--side), calc(50% - var(--mobile) / 2));
    --time: 250ms;

    @media #{$mobileScreen} {
        --body-side: min(var(--side), calc(50% - var(--mobile) / 2));
    }

    @media #{$desktopScreen} {
        --body-side: max(var(--side), calc(50% - var(--desktop) / 2));
    }
}

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style-type: none;
    outline: transparent !important;
    text-decoration: none;
}