// MAIN

.wrapper_main {
    position: relative;
    padding-left: var(--body-side);
    padding-right: var(--body-side);
    padding-bottom: var(--gallery-gap-mobile);

    @media #{$mobileScreen} {
        padding-bottom: calc(var(--side) * 2);
    }

    @media #{$desktopScreen} {
        padding-bottom: 0;
    }
}

.gallery {
    display: flex;
    flex-direction: column-reverse;

    @media #{$mobileScreen} {
        flex-direction: row;
    }
}

.gallery>* {
    flex: 1;
    display: flex;
    place-items: center;
    flex-direction: column;
}

.gallery_profil {
    box-shadow: 0px 1px 5px var(--shadow-color);

    &.mobileProfil {
        @media #{$mobileScreen} {
            display: none;
        }
    }

    &.desktopProfil {
        display: none;

        @media #{$mobileScreen} {
            display: flex;
        }
    }
}

.gallery_bonus {
    display: none;
}

.gallery_left {
    @media #{$mobileScreen} {
        margin-right: var(--side);
        margin-top: min(calc(var(--gallery-gap) * 2), 24rem);
    }

    @media #{$desktopScreen} {
        margin-top: min(calc(var(--gallery-gap) * 2), 18rem);
    }
}

.gallery_right {
    @media #{$mobileScreen} {
        margin-left: var(--side);
    }
}

.gallery_right .gallery_item {
    margin-bottom: 0;
}

.gallery_item {
    width: 100%;
    margin-top: var(--gallery-gap-mobile);
    border-radius: var(--corner-item);

    @media #{$mobileScreen} {
        margin-top: var(--gallery-gap);
        max-width: calc(var(--index) * var(--multi-width-item));
    }

    @media #{$tabletScreen} {
        margin-top: var(--gallery-gap);
    }
}

.gallery_drawing {
    cursor: zoom-in;
    position: relative;
    box-shadow: 0px 1px 5px var(--shadow-color);

    &::before {
        content: '@artist reserved';
        position: absolute;
        bottom: 0;
        left: 5px;
        font-size: .6em;
    }

    &::after {
        content: '';
        cursor: pointer;
        position: absolute;
        bottom: .5rem;
        right: .5rem;
        background-image: url(../assets/img/icons/zoom.svg);
        background-repeat: no-repeat;
        background-size: 22px 22px;
        background-position: center;
        background-color: transparent;
        border: 2px solid var(--line-color-opacity);
        border-radius: calc(var(--corner-item) / 2);
        height: 2.5rem;
        width: 2.5rem;
        transition: all var(--time);
    }

    &:hover::after {
        border: 2px solid var(--important-color);
        background-color: var(--important-color-opacity);
    }
}

.rights_light {
    color: var(--white-light);
}

.no_right {
    color: transparent;
}

.gallery_paragraph {
    border: 3px solid var(--border-paragraph);
    padding: 1rem;

    @media #{$mobileScreen} {
        max-width: calc(var(--index) * calc(var(--multi-width-item) + 10));
    }
}

.description_block {
    position: relative;
    text-align: center;

    @media #{$mobileScreen} {
        text-align: left;
    }

    .title_description_block {
        font-size: var(--title-size);
        margin-bottom: 4vh;
        line-height: 1.1;
    }

    .text_description_block,
    .city,
    .list {
        font-size: .85em;

        @media #{$tabletScreen} {
            font-size: 1em;
        }
    }

    .city {
        text-decoration: underline;
    }

    .list li {
        list-style-position: inside;
        list-style-image: url(../assets/img/icons/diamond.svg);
    }

    .br {
        margin-bottom: 2vh;
    }

    br {
        display: flex;
        height: 2vh;
    }
}

.carousel {
    --side-picture: 50px;
    position: relative;
    overflow: hidden;
    background: var(--second-background);
    box-shadow: 0px 2px 5px var(--shadow-color);
    padding-left: var(--side-picture);
    padding-right: var(--side-picture);

    @media #{$mobileScreen} {
        max-width: calc(var(--index) * calc(var(--multi-width-item) - 2) + calc(var(--side-picture) * 2));
    }

    #switchBlock {
        display: flex;
        place-items: center;
        margin-top: calc(var(--side-picture) / 2);
        margin-bottom: calc(var(--side-picture) * 1.5);

        .picture {
            cursor: grab;
            position: relative;
            height: auto !important;
            border-radius: var(--corner-item);
            box-shadow: none;
            transition: transform 650ms, border var(--time);
            transition-delay: 50ms;

            &::before {
                content: '@artist reserved';
                position: absolute;
                bottom: 0;
                left: 5px;
                font-size: .6em;
            }

            .img_carousel {
                border-radius: 0;
            }
        }
    }

    .zoom_button {
        content: '';
        cursor: pointer;
        position: absolute;
        bottom: .5rem;
        right: .5rem;
        background-image: url(../assets/img/icons/zoom.svg);
        background-repeat: no-repeat;
        background-size: 22px 22px;
        background-position: center;
        background-color: transparent;
        border: 2px solid var(--line-color-opacity);
        border-radius: calc(var(--corner-item) / 2);
        height: 2.5rem;
        width: 2.5rem;
        transition: all var(--time);
    }

    .zoom_button:hover {
        border: 2px solid var(--important-color);
        background-color: var(--important-color-opacity);
    }

    .points_container {
        display: flex;
        gap: 5px;
        position: absolute;
        left: 50%;
        bottom: 2%;
        transform: translate(-50%, 0%);

        @media #{$mobileScreen} {
            bottom: 4%;
        }
    }

    .points {
        background: var(--white-light-opaque-up);
        width: 10px;
        height: 10px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        transition: background 2000ms, transform 750ms;
    }

    .buttonLeft,
    .buttonRight {
        cursor: pointer;
        background-repeat: no-repeat;
        width: calc(var(--index) * 2);
        min-width: 25px;
        max-width: 60px;
        height: auto;
        aspect-ratio: 1/1;
        border-radius: 50%;
        position: absolute;
        bottom: 1%;
        visibility: hidden;
        opacity: 0;
        animation-duration: 1000ms;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        transition: background calc(var(--time) / 2), opacity var(--time), visibility var(--time);

        @media #{$mobileScreen} {
            bottom: 2%;
        }

        &:hover {
            animation: none;
            background-color: var(--orange);
        }
    }

    .buttonLeft {
        background-image: url(../assets/img/icons/arrow-left.svg);
        left: 3%;
    }

    .buttonRight {
        background-image: url(../assets/img/icons/arrow-right.svg);
        right: 3%;
    }

    @keyframes scale {
        from {
            transform: scale(1)
        }

        to {
            transform: scale(.95);
        }
    }
}