// HEADER

.header {
    padding-left: var(--body-side);
    padding-right: var(--body-side);
    position: relative;
    text-align: center;
    min-height: 100vh;

    .img_header {
        background: var(--main-background);
        max-width: 100%;
        margin-top: 2rem;
        width: calc(var(--index) * 30);
        z-index: -1;

        @media #{$mobileScreen} {
            position: absolute;
            top: 8vh;
            left: auto;
            right: 0vw;
            transform: translate(0%, 0%);
            margin-top: 0;
        }

        @media #{$UpScreen} {
            right: 5%;
        }
    }
}

.wrapper_header {
    position: relative;

    @media #{$mobileScreen} {
        min-height: 100vh;
    }

    .description_header {
        text-align: center;
        width: 100%;

        @media #{$mobileScreen} {
            position: absolute;
            text-align: left;
            width: 60%;
            margin-left: 7vw;
            bottom: 20vh;
        }

        @media #{$UpScreen} {
            margin-left: 0;
        }
    }

    .title_header {
        font-size: calc(var(--index) * 6);
        line-height: 1.1;
    }

    .text_header {
        color: var(--title-color);
        font-weight: var(--font-semibold);
        line-height: 1.5;
    }
}